.table-container {
  width: 100%;
  overflow-x: auto;
}

.table-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  padding: 1.5rem;
}

.table-options div {
  align-items: center;
}
.input-container {
  margin-left:auto;
}
.search-input {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  border-radius: 0.375rem;
  height: 2.3rem;
  padding: 0.65rem;
  font-size: 0.75rem;
  border: 0.75px solid #b1b0b0;
  width: 12rem;
}

.search-input::placeholder {
  color: #b1b0b0;
}

.search-input, select, textarea {
  color: #646363;
}

.object_per_page-text {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  font-size: 0.75rem;
  color: #b1b0b0;
  margin-left: 0.5rem;
}

.object_per_page-input {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  border-radius: 0.375rem;
  height: 2.3rem;
  padding: 0.65rem;
  font-size: 0.75rem;
  border: 0.75px solid #b1b0b0;
  width: 4rem;
}

.search-input::placeholder {
  color: #b1b0b0;
}

.search-input, select, textarea {
  color: #646363;
}


.table {
  width: 100%;
  border-color: gray;
}

.table th,
.table td {
  border-bottom: 0.5px solid #dddddd;
  padding: 0.75rem;
  padding-right: 1.15rem;
  padding-left: 1.15rem;
  text-align: left;
}

.table td {
  opacity: 1;
  background: transparent;
  color: rgb(123, 128, 154);
  box-shadow: none;
  vertical-align: middle;
  font-size: 0.875rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.625;
  letter-spacing: 0.00938em;
  white-space: nowrap; 
 
}

.table th {
  color: #7b809a;
  font-size: 0.65rem;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.7;
  background: transparent;
  cursor: pointer;
}

.pagination-container {
  padding: 1.5rem;
}

.pagination-button {
  border: 1px solid rgb(222, 226, 230);
  border-radius: 50%; /* Make it a perfect circle */
  width: 2.2rem; /* Set a fixed width and height */
  height: 2.2rem;
  padding: 0; /* Remove padding */
  background-color: transparent;
  color: black;
  cursor: pointer;
  outline: none; /* Remove default focus outline */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition on hover */
  color: rgb(123, 128, 154);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  font-size: 1.1rem;
  background-image: none;
  box-shadow: none;
}

.pagination-button-selected {
  border: 0px solid rgb(123, 128, 154);
  border-radius: 50%; /* Make it a perfect circle */
  width: 2.2rem; /* Set a fixed width and height */
  height: 2.2rem;
  padding: 0; /* Remove padding */
  background-color: transparent;
  color: white;
  cursor: pointer;
  outline: none; /* Remove default focus outline */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition on hover */
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  font-size: 1.1rem;
  background-image: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  box-shadow: rgba(26, 115, 232, 0.15) 0rem 0.1875rem 0.1875rem 0rem, rgba(26, 115, 232, 0.2) 0rem 0.1875rem 0.0625rem -0.125rem, rgba(26, 115, 232, 0.15) 0rem 0.0625rem 0.31

}

.pagination-button:hover {
  background-color: rgb(234, 234, 234);
}